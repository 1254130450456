<template>
    <div class="rule">
        <div class="vote-rule">
            <h3 class="title">投票规则</h3>
            <Form :model="formValidate" :label-colon="true" :label-width="140" :rules="ruleValidate" ref="formValidate" disabled>
                <FormItem label="投票类型" prop="voteType">
                    <p>{{ formValidate.voteTypeList[formValidate.voteType - 1].dictValue }}</p>
                </FormItem>
                <FormItem label="周期设置" prop="voteCycle">
                    <p>{{ formValidate.voteCycleList[formValidate.voteCycle - 1].dictValue }}</p>
                </FormItem>
                <FormItem label="【单选】票数设置" class="number-vote" v-if="formValidate.voteType == '1'">
                    <div class="number-vote-setting">
                        <span>每个用户可以投</span>
                        <span style="font-size: 18px; margin: 0 6px">{{ formValidate.voteMaxAccount }}</span>
                        <span>次</span>
                    </div>

                    <div class="number-vote-setting">
                        <span>可为同一选手投</span>
                        <span style="font-size: 18px; margin: 0 6px">{{ formValidate.voteMaxItem }}</span>
                        <span>次</span>
                    </div>
                </FormItem>
                <FormItem label="【多选】票数设置" class="number-vote" v-else>
                    <div class="number-vote-setting">
                        <span>最少选择</span>
                        <span style="font-size: 18px; margin: 0 6px">{{ formValidate.selectMinItem }}</span>
                        <span>个选项</span>
                    </div>
                    <div class="number-vote-setting">
                        <span>最多选择</span>
                        <span style="font-size: 18px; margin: 0 6px">{{ formValidate.selectMaxItem }}</span>
                        <span>个选项</span>
                    </div>
                </FormItem>
                <FormItem label="每年总数量限制" prop="voteMaxAccount">
                  <p>{{ formValidate.voteMaxAccount }}</p>
                </FormItem>
            </Form>
        </div>

        <div class="page-element">
            <h3 class="title">页面元素</h3>
            <Form :model="formValidate" :rules="ruleValidate" :label-colon="true" :label-width="140" ref="formValidate1">
                <FormItem label="选手称呼" prop="voteItemName">
                    <p>{{ formValidate.voteItemName || "无" }}</p>
                </FormItem>
                <FormItem label="投票按钮名称" prop="buttonName">
                    <p>{{ formValidate.buttonName || "无" }}</p>
                </FormItem>
                <FormItem label="票数名称" prop="buttonUnit">
                    <p>{{ formValidate.buttonUnit || "无" }}</p>
                </FormItem>
            </Form>
        </div>
    </div>
</template>

<script>
export default {
    props: ["formValidate", "ruleValidate"],
    data() {
        return {}
    },
    methods: {
        backStep() {
            this.$emit("nextStep", -1)
        },
        nextStep() {
            this.$refs.formValidate.validate(valid => {
                this.$refs.formValidate1.validate(valid1 => {
                    if (valid && valid1) {
                        this.$emit("nextStep", 1)
                    }
                })
            })
        },
    },
}
</script>
    
<style scoped lang='less'>
.rule {
    width: 100%;
    .ivu-form {
        margin: 0 30px;
    }
    .number-vote {
        .number-vote-setting {
            display: flex;
            margin-right: 20px;
            float: left;
        }
    }
    .ivu-input-number {
        margin: 0 10px;
    }
    .page-element {
        .ivu-select {
            width: 30%;
        }
    }
    .bottom {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        button {
            margin-left: 20px;
        }
    }
}
</style>